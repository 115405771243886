<template>
  <div class="price-calendar-page">
    <CRow>
      <CCol
        md="12"
        lg="12"
      >
        <CCard>
          <CCardHeader>
            <strong> {{$t('priceCalendarEditPage.pageTitle')}}</strong>
          </CCardHeader>
          <CCardBody>
            <div class="season-type-calendar">
              <CRow>
                <CCol md="9">
                  <CCard>
                    <CCardBody>
                      <section class="custom-calendar-select">
                        <!-- #region calendar header  -->
                        <table class="calendar-top">
                          <tr class="month-navi">
                            <th>
                              <CButton
                                class="prev py-0 mx-0"
                                @click.prevent="changeMonth(false)"
                              >
                                <i class="bi bi-chevron-left"></i>
                              </CButton>
                            </th>
                            <th colspan="5">

                              <strong
                                class="calendar__title"
                                @click.prevent="backToToday()"
                              >
                                <span class="caption-year">{{current.year}} 年</span>
                                <span class="caption-month">{{convertTwoDigits}} 月</span>
                              </strong>
                            <th>
                              <CButton
                                class="prev py-0 mx-0"
                                @click.prevent="changeMonth(true)"
                              ><i class="bi bi-chevron-right "></i></CButton>
                            </th>
                          </tr>
                          <tr class="calendar-week">
                            <th
                              v-for="week in heading"
                              :key="week"
                            >{{week}}</th>
                          </tr>
                        </table>
                        <!-- #endregion calendar header -->

                        <!-- #region calendar body  -->
                        <table
                          class="raw-calendar"
                          @mousedown="mouseDown"
                          @mouseup="mouseUp"
                          @mousemove="mouseMove"
                          @click="clickCell"
                        >
                          <tr
                            v-for="(tr, rowIndex) in rows"
                            :key="rowIndex"
                          >
                            <td
                              v-for="(date, cellIndex) in tr.table_cells"
                              :key="cellIndex"
                              :id="'eLabel'+date.date.date"
                              class="date-cell"
                              :class="{'is-active': isActive(rowIndex, cellIndex)}"
                            >
                              {{date.date.cDate}}

                              {{selectedkk(date,isActive(rowIndex, cellIndex))}}

                            </td>
                          </tr>
                        </table>
                        <!-- #endregion calendar body  -->
                      </section>
                      <!-- custom calendar section end  -->

                    </CCardBody>
                  </CCard>
                </CCol>
                <CCol md="3">
                  <div class="season-sidebar">
                    <CCard>
                      <CCardHeader color="dark">
                        <strong class="text-white">{{$t('priceCalendarEditPage.priceType')}}</strong>
                      </CCardHeader>
                      <!-- region sidebar price type  -->
                      <CCardBody>
                        <CListGroup :key="reload">
                          <template v-for="(type) in priceTypes">
                            <CListGroupItem
                              :key="type.id"
                              class="p-0 rounded-0"
                            >
                              <div class="flex-container">
                                <span
                                  class="btmn"
                                  @click="selectType(type)"
                                >
                                  <CButton
                                    size="sm"
                                    class="select-bg-type"
                                    :class="type.isSelect? 'color-selected':''"
                                    v-bind:style="{ background: type.color}"
                                  ></CButton>
                                  <strong>{{type.label | wordLimit(10, '...') }}</strong>
                                  <span
                                    v-if="type.isSelect"
                                    class="select-lbl"
                                    v-bind:style="{ background: type.color}"
                                  >✔</span>
                                </span>
                                <CButton
                                  size="sm"
                                  color="info"
                                  class="px-2 my-1 mr-2 edit-b"
                                  @click="editType(type)"
                                > <i class="icon cil-pencil mr-1"></i>{{$t('common.edit')}}</CButton>
                              </div>
                            </CListGroupItem>
                          </template>
                        </CListGroup>

                        <CRow class="mt-2">
                          <CCol>
                            <CButton
                              size="sm"
                              color="success"
                              @click="addTypeModal = true"
                            ><i class="icon cil-plus mr-1"></i>{{$t('priceCalendarEditPage.addType')}}</CButton>
                            <CButton
                              size="sm"
                              color="danger"
                              class="float-right"
                              @click="clearTypeSelect()"
                            ><i class="icon cil-x mr-1"></i>{{$t('priceCalendarEditPage.clearSelection')}}</CButton>
                          </CCol>
                        </CRow>
                        <!--end region sidebar price type  -->

                        <CModal
                          color="success"
                          size="lg"
                          centered
                          :closeOnBackdrop="false"
                          :show.sync="addTypeModal"
                        >
                          <div class="add-modal">
                            <CForm class="submit_form">
                              <CRow>
                                <CCol class="label"><strong class="required-mark">{{$t('priceCalendarEditPage.nameType')}}</strong></CCol>
                                <CCol>
                                  <CInput v-model="newPriceType.label" />
                                </CCol>
                              </CRow>
                              <CRow>
                                <CCol class="label"><strong class="required-mark">{{$t('priceCalendarEditPage.colorType')}}</strong></CCol>
                                <CCol class="col-pick">
                                  <v-swatches
                                    v-model="newPriceType.color"
                                    popover-y="bottom"
                                    :swatches="swatches"
                                  ></v-swatches>

                                </CCol>
                              </CRow>
                            </CForm>
                          </div>
                          <template #header>{{$t('priceCalendarEditPage.addTypeTitle')}}</template>
                          <template #footer>
                            <CButton
                              @click="addTypeModal=false"
                              color="secondary"
                            >
                              <i class="icon cil-x"></i> {{$t('common.close')}}
                            </CButton>
                            <CButton
                              @click="addNewType()"
                              color="success"
                            >
                              <i class="icon cil-save mr-1"></i> {{$t('common.save')}}
                            </CButton>
                          </template>
                        </CModal>
                        <CModal
                          color="dark"
                          size="lg"
                          centered
                          :closeOnBackdrop="false"
                          :show.sync="editTypeModal"
                        >
                          <div class="add-modal">
                            <CForm class="submit_form">
                              <CRow>
                                <CCol class="label"><strong class="required-mark">{{$t('priceCalendarEditPage.nameType')}}</strong></CCol>
                                <CCol>
                                  <CInput v-model="editPriceType.label" />
                                </CCol>
                              </CRow>
                              <CRow>
                                <CCol class="label"><strong class="required-mark">{{$t('priceCalendarEditPage.colorType')}}</strong></CCol>
                                <CCol class="col-pick">
                                  <v-swatches
                                    v-model="editPriceType.color"
                                    popover-y="bottom"
                                    :swatches="swatches"
                                  ></v-swatches>

                                </CCol>
                              </CRow>
                            </CForm>
                          </div>
                          <template #header>{{$t('priceCalendarEditPage.editType')}}</template>
                          <template #footer>
                            <CButton
                              @click="editTypeModal=false"
                              color="secondary"
                            >
                              <i class="icon cil-x"></i> {{$t('common.close')}}
                            </CButton>
                            <CButton
                              @click="deleteConfirmTypeModal=true; editTypeModal=false;"
                              color="danger"
                            >
                              <i class="icon cil-trash"></i> {{$t('common.delete')}}
                            </CButton>
                            <CButton
                              @click="updateTypeEdit()"
                              color="success"
                            >
                              <i class="icon cil-save"></i> {{$t('common.update')}}
                            </CButton>
                          </template>
                        </CModal>
                        <CModal
                          color="danger"
                          size="lg"
                          centered
                          :closeOnBackdrop="false"
                          :show.sync="deleteConfirmTypeModal"
                        >
                          <div class="add-modal">
                            {{$t('priceCalendarEditPage.deleteConfirm')}}

                          </div>
                          <template #header>{{$t('priceCalendarEditPage.addTypeTitle')}}</template>
                          <template #footer>
                            <CButton
                              @click="deleteConfirmTypeModal=false"
                              color="secondary"
                            >
                              <i class="icon cil-x"></i> {{$t('common.close')}}
                            </CButton>
                            <CButton
                              @click="deleteType()"
                              color="success"
                            >
                              <i class="icon cil-trash"></i> {{$t('common.delete')}}
                            </CButton>
                          </template>
                        </CModal>
                      </CCardBody>
                    </CCard>
                  </div>
                </CCol>
              </CRow>

            </div>
            <!-- season type calandar end  -->

          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <!-- TODO: -->
  </div>
</template>

<script>
import { scanTable } from "@/components/scantable";
import moment from "moment";
import VSwatches from "vue-swatches";
import "vue-swatches/dist/vue-swatches.css";
export default {
  name: "PriceCalendar",
  components: {
    VSwatches,
  },
  data() {
    return {
      addTypeModal: false,
      editTypeModal: false,
      deleteConfirmTypeModal: false,
      selected: [],
      days: [],
      getServerDate: [],
      singleDate: "",
      SelectedPriceType: {},
      attributes: [],
      test: "",
      priceTypes: [
        {
          id: 1,
          label: "Spring",
          color: "#f9b115",
        },
        {
          id: 2,
          label: "Summer",
          color: "#dd7e6b",
        },
      ],
      newPriceType: {
        id: "",
        label: "",
        color: "",
      },
      editPriceType: {
        label: "",
        color: "",
      },
      // default color selection code
      swatches: [
        "#cccccc",
        "#dd7e6b",
        "#ea9999",
        "#f9cb9c",
        "#ffe599",
        "#b6d7a8",
        "#a2c4c9",
        "#a4c2f4",
        "#9fc5e8",
        "#b4a7d6",
        "#d5a6bd",
        "#ffffff",
      ],
      reload: 1,
      currentCells: [],
      startCell: [],
      endCell: [],
      selectedDate: [],
      isDrag: false,

      // calendar section start
      current: {
        year: 0,
        month: 0,
        date: 0,
        cDate: "",
      },
      today: {
        year: 0,
        month: 0,
        date: 0,
        cDate: "",
      },
      heading: ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"],
    };
  },
  beforeMount() {
    this.getDateProp();
  },
  mounted: function () {
    this.dynamicCSSClass();
  },
  beforeUpdate: function () {
    this.dynamicCSSClass();
  },
  methods: {
    /**
     *  get all calendar data
     *
     */
    getDateProp() {
      this.getServerDate = [
        {
          id: "20210524",
          country: "jpn",
          date: "2021-07-1",
          type: 0,
          label: "Spring",
          color: "#f9b115",
        },
        {
          id: "20210526",
          country: "jpn",
          date: "2021-08-26",
          type: 0,
          label: "Fall",
          color: "#cccccc",
        },
        {
          id: "20210526",
          country: "jpn",
          date: "2021-07-26",
          type: 0,
          label: "Fall",
          color: "#cccccc",
        },
      ];

      this.rows.forEach((r) => {
        r.table_cells.forEach((c) => {
          this.getServerDate.forEach((l) => {
            // console.log(c.date.date);
            // var newCDAte = new Date(c.date.date)
            // console.log(moment(newCDAte).format("MMM Do YYYY"));
            if (
              moment(l.date).format("MMM Do YYYY") ==
              moment(c.date.date).format("MMM Do YYYY")
            ) {
              c.type = l.type;
              c.label = l.label;
              c.color = l.color;
              // console.log("Date**" + JSON.stringify(l.date));
            }
          });
        });
      });
    },

    // <===================calendar section start ========================>

    /**
     *  match day with dragg selection
     *
     */
    selectedkk(opt, val) {
      opt.date.cDate ? (opt.selected = val) : (opt.selected = false);
    },
    changeMonth(isNext) {
      let month = this.current.month;
      isNext === true ? (month = month + 1) : (month = month - 1);
      if (month <= 0) {
        month = 12;
        this.current.year = this.current.year - 1;
      }
      if (month > 12) {
        month = 1;
        this.current.year = this.current.year + 1;
      }
      this.current.month = month;
      this.current.date = 1;
      this.getDateProp();
      this.clear();
    },
    getDateData(data) {
      if (data.none === true) {
        return false;
      } else {
        if (
          data.years === this.current.year &&
          data.month === this.current.month &&
          data.date === this.current.date &&
          data.cDate === this.current.cDate
        ) {
          return false;
        } else {
          this.current.year = data.years;
          this.current.month = data.month;
          this.current.date = data.date;
          this.current.cDate = data.cDate;
        }
      }
    },

    /**
     * Button: return to current date
     * */
    backToToday() {
      this.current.year = this.today.year;
      this.current.month = this.today.month;
      this.current.date = this.today.date;
      this.current.cDate = this.today.cDate;
    },

    /**
     *  show current date information
     *
     */
    getToday() {
      this.today.year = moment().year();
      this.today.month = moment().month() + 1;
      this.today.date = moment().date();
      this.today.cDate = moment().format("MMM Do YYYY");
    },

    /// ================= dragg region start==================

    /*
     * Give cell a selection status
     */
    isActive(rowIndex, cellIndex) {
      return (
        this.currentCells.findIndex(
          (elem) => elem.rowIndex == rowIndex && elem.cellIndex == cellIndex
        ) > -1
      );
    },

    /*
     * Change the selection status by clicking
     */
    clickCell(event) {
      const cell = event.target;
      const tr = event.target.parentNode;
      if (this.isActive(tr.rowIndex, cell.cellIndex)) {
        const rmIndex = this.currentCells.findIndex(
          (elem) =>
            elem.rowIndex == tr.rowIndex && elem.cellIndex == cell.cellIndex
        );

        this.currentCells = [
          ...this.currentCells.slice(0, rmIndex),
          ...this.currentCells.slice(rmIndex + 1),
        ];
      } else {
        this.currentCells = [
          ...this.currentCells,
          {
            rowIndex: tr.rowIndex,
            cellIndex: cell.cellIndex,
          },
        ];
      }
    },

    /**
     * Get the maximum value of the element (number of cells)
     *  in the row (for row creation)
     *
     */
    getMaxCellNum() {
      return this.rows.reduce((acc, tr) => {
        if (acc < tr.table_cells.length) {
          return tr.table_cells.length;
        } else {
          return acc;
        }
      }, 0);
    },
    /** clear all selection */
    clear() {
      this.currentCells = [];
    },

    /** dragg select */
    mouseDown(e) {
      console.log(e);
      console.log("mouseDown:", e);
      this.isDrag = true;

      this.startCell = {
        rowIndex: e.target.parentNode.rowIndex,
        cellIndex: e.target.cellIndex,
        rowspan: e.target.rowSpan,
        colspan: e.target.colSpan,
      };
      console.log("startcell:", this.startCell);
    },

    /**
     * dragg mouse up
     *  */
    mouseUp(e) {
      console.log("mouseUp:", e);
      this.isDrag = false;
    },

    /**
     *  hold and dragg  mouse
     * */
    mouseMove(e) {
      if (
        this.isDrag == true &&
        e.target.tagName != "TABLE" &&
        e.offsetX > 20 &&
        e.offsetY > 20
      ) {
        this.endCell = {
          rowIndex: e.target.parentNode.rowIndex,
          cellIndex: e.target.cellIndex,
          rowspan: e.target.rowSpan,
          colspan: e.target.colSpan,
        };
        // console.log("endCell:", this.endCell);
      }
    },

    // <=================price type selection start ================>

    /**
     *  price type: select a price type
     * */
    selectType(type) {
      // console.log(type.label);
      this.SelectedPriceType = type;
      this.priceTypes.forEach((e) => {
        e.id == type.id ? (e.isSelect = true) : (e.isSelect = false);
      });
      // console.log("data:" + JSON.stringify(this.SelectedPriceType));
      // console.log("price:" + JSON.stringify(this.priceTypes));

      this.rows.forEach((r) => {
        r.table_cells.forEach((c) => {
          if (c.selected) {
            c.label = type.label;
            c.color = type.color;
          }
        });
      });
      this.currentCells = [];
      this.reload++;
    },

    /**
     * clear all dragg selected date
     * */
    clearSelectedpriceType() {
      this.rows.forEach((r) => {
        r.table_cells.forEach((c) => {
          if (c.selected) {
            delete c.label;
            delete c.color;
          }
        });
      });
      this.currentCells = [];
      this.reload++;
    },

    /**
     * price type: clear selected of a price type
     *  */
    clearTypeSelect() {
      this.SelectedPriceType = null;
      this.priceTypes.forEach((e) => {
        e.isSelect = false;
        this.reload++;
      });
      this.clearSelectedpriceType();
    },

    /**
     * price type: edit a price type
     * */
    editType(type) {
      this.editTypeModal = true;
      this.editPriceType = type;
    },

    /**
     * price type: delete type
     *  */
    deleteType() {
      this.priceTypes.forEach((a, i, object) => {
        if (a == this.editPriceType) {
          object.splice(i, 1);
        }
      });
      this.deleteConfirmTypeModal = false;
    },

    /**
     * price type: add a new price type
     * */
    addNewType() {
      this.addTypeModal = false;
      this.newPriceType.id = Math.floor(Math.random() * 100); // delete when api will be completed;
      this.priceTypes.push(this.newPriceType);
      this.newPriceType = {};
    },

    /**
     * price type: submit edited data
     * */
    updateTypeEdit() {
      this.editTypeModal = false;
    },

    /**
     *  style class: get class name from day attributes
     */
    getDayAttrClass(dd) {
      return dd.attributesMap && Object.keys(dd.attributesMap).length > 0
        ? Object.values(dd.attributesMap)[0].customData.typeData
        : "";
    },

    /**
     * get a custom data from a date attributes
     * */
    getDayAttr(a) {
      return a.attributesMap
        ? a.attributesMap[Object.keys(a.attributesMap)[0]].customData
        : "";
    },

    /**
     * click: set a attributes of a date data
     * */
    selectDateB(day) {
      if (this.SelectedPriceType && this.SelectedPriceType.isSelect) {
        if (day.attributes && day.attributes.length > 0) {
          var dd = this.getDayAttr(day);
          dd.typeData = this.SelectedPriceType;
        } else {
          this.attributes.push({
            customData: {
              typeData: this.SelectedPriceType,
            },
            dates: day.date,
          });
        }
      }
      if (this.SelectedPriceType == null) {
        if (day.attributes && day.attributes.length > 0) {
          var bla = {
            // name: "",
            label: "",
            color: "",
            // colorIndex: "",
            isSelect: false,
          };
          var clearDD = this.getDayAttr(day);
          clearDD.typeData = bla;
          console.log("clear:" + clearDD);
        }
      }
      this.singleDate = day;
    },

    /**
     * import day label into style after
     * */
    dynamicCSSClass() {
      let nodeId = "vue-ribbon-bkg-" + this._uid;
      let style = document.getElementById(nodeId);
      if (!style) {
        style = document.createElement("style");
        style.id = nodeId;
        style.type = "text/css";
        this.$el.appendChild(style);
      }

      var allDateAtr = [];
      this.rows.forEach((r) => {
        r.table_cells.forEach((c) => {
          allDateAtr.push(c);
        });
      });
      let dStyle = [];
      allDateAtr.forEach((lData) => {
        if (lData.label) {
          var llt;
          llt = `#eLabel${lData.date.date}:before { content: none;}`;
          llt += `#eLabel${lData.date.date}:after { background-color: ${lData.color}; content:'${this.$options.filters.wordLimit(lData.label, 15, '...')}'; position: absolute; bottom:0; left:0; width:100%}`;
          llt += `#eLabel${lData.date.date} { content: none; } `;
          dStyle.push(llt);
        }
      });

      style.innerHTML = dStyle;
    },
  },
  computed: {
    /** return selected cell
     *
     */
    colIndices() {
      return scanTable(this.rows);
    },

    /** all date value for calendar box
     *
     */
    rows() {
      let myYears = this.current.year;
      let myMonth = this.current.month;
      let myDate = this.current.date;
      let myCDate = this.current.cDate;

      let monthText = "";
      myMonth < 10
        ? (monthText = `0${myMonth}`)
        : (monthText = myMonth.toString());

      let dateArray = [];
      let patchNum = 0;
      let totalDate = moment(`${myYears}-${monthText}`).daysInMonth();
      let week = moment(`${myYears}-${monthText}`).format("d");
      // console.log(totalDate);

      for (let i = 0; i < totalDate; i++) {
        let dateNum = i + 1;
        let isToday = false;
        let isCurrent = false;
        // var format = "dddd, D [de] MMMM [de] YYYY";
        // console.log(`${myYears}-${monthText}-${i}`)
        let CCDate = `${myYears}-${monthText}-${i + 1}`; // set a formate
        let dateText = "";

        if (
          myYears === this.today.year &&
          myMonth === this.today.month &&
          dateNum === this.today.date &&
          myCDate === this.today.cDate
        ) {
          isToday = true;
        }

        if (dateNum === myDate) {
          isCurrent = true;
        }

        dateNum < 10
          ? (dateText = `0${dateNum}`)
          : (dateText = dateNum.toString());

        let obj = {
          years: myYears,
          month: myMonth,
          date: CCDate,
          cDate: dateNum,
          number: dateText,
          today: isToday,
          current: isCurrent,
        };
        dateArray.push(obj);
      }

      // Previous
      for (let i = 0; i < week; i++) {
        let obj = {
          number: "",
          none: true,
        };
        dateArray.splice(i, 0, obj);
      }

      // back
      dateArray.length % 7 === 0
        ? (patchNum = 0)
        : (patchNum = 7 - (dateArray.length % 7));

      for (let i = 0; i < patchNum; i++) {
        let obj = {
          number: "",
          none: true,
        };
        dateArray.splice(dateArray.length, 0, obj);
      }

      var arrays = [];
      var size = 7;
      while (dateArray.length > 0) {
        arrays.push(dateArray.splice(0, size));
      }

      var fullTable = [];
      arrays.forEach((e) => {
        // console.log(e);
        var kkl = [];
        fullTable.push({ table_cells: kkl });

        e.forEach((a) => {
          kkl.push({ date: a, selected: false });
        });
      });

      return fullTable;
    },
    convertTwoDigits() {
      let text = "";
      this.current.month < 10
        ? (text = `0${this.current.month}`)
        : (text = this.current.month);
      return text;
    },
  },
  created() {
    this.getToday();
    this.backToToday();
  },
  watch: {
    endCell() {
      this.currentCells = [this.startCell];

      // 基点となるrowとcol
      let startRowIndex = this.startCell.rowIndex;
      if (startRowIndex > this.endCell.rowIndex) {
        startRowIndex = this.endCell.rowIndex;
      }

      let startColIndex =
        this.colIndices[this.startCell.rowIndex][this.startCell.cellIndex];
      if (
        startColIndex >
        this.colIndices[this.endCell.rowIndex][this.endCell.cellIndex]
      ) {
        startColIndex =
          this.colIndices[this.endCell.rowIndex][this.endCell.cellIndex];
      }

      let endRowIndex = this.startCell.rowIndex + this.startCell.rowspan - 1;
      if (endRowIndex < this.endCell.rowIndex + this.endCell.rowspan - 1) {
        endRowIndex = this.endCell.rowIndex + this.endCell.rowspan - 1;
      }

      let endColIndex =
        this.colIndices[this.startCell.rowIndex][this.startCell.cellIndex] +
        this.startCell.colspan -
        1;
      if (
        endColIndex <
        this.colIndices[this.endCell.rowIndex][this.endCell.cellIndex] +
          this.endCell.colspan -
          1
      ) {
        endColIndex =
          this.colIndices[this.endCell.rowIndex][this.endCell.cellIndex] +
          this.endCell.colspan -
          1;
      }

      //対象セルの抽出
      const dragSelectedCells = () => {
        for (let i = 0; i <= endRowIndex; i++) {
          for (let j = 0; j < this.rows[i].table_cells.length; j++) {
            //範囲拡張チェック
            //colIndexの延長処理
            if (
              (i <= startRowIndex &&
                startRowIndex <= i + this.rows[i].table_cells[j].rowspan - 1) ||
              (i <= startRowIndex &&
                startRowIndex <= i + this.rows[i].table_cells[j].rowspan - 1) ||
              (startRowIndex <= i &&
                i + this.rows[i].table_cells[j].rowspan - 1 <= endRowIndex)
            ) {
              if (
                this.colIndices[i][j] < startColIndex &&
                startColIndex <=
                  this.colIndices[i][j] +
                    this.rows[i].table_cells[j].colspan -
                    1
              ) {
                startColIndex = this.colIndices[i][j];
                dragSelectedCells();
              }

              if (
                this.colIndices[i][j] <= endColIndex &&
                endColIndex <
                  this.colIndices[i][j] +
                    this.rows[i].table_cells[j].colspan -
                    1
              ) {
                endColIndex =
                  this.colIndices[i][j] +
                  this.rows[i].table_cells[j].colspan -
                  1;
                dragSelectedCells();
              }
            }

            //rowIndexの延長処理
            if (
              (this.colIndices[i][j] <= startColIndex &&
                startColIndex <=
                  this.colIndices[i][j] +
                    this.rows[i].table_cells[j].colspan -
                    1) ||
              (this.colIndices[i][j] <= endColIndex &&
                endColIndex <=
                  this.colIndices[i][j] +
                    this.rows[i].table_cells[j].colspan -
                    1) ||
              (startColIndex <= this.colIndices[i][j] &&
                this.colIndices[i][j] +
                  this.rows[i].table_cells[j].colspan -
                  1 <=
                  endColIndex)
            ) {
              if (
                i < startRowIndex &&
                startRowIndex <= i + this.rows[i].table_cells[j].rowspan - 1
              ) {
                startRowIndex = i;
                dragSelectedCells();
              }

              if (
                i <= endRowIndex &&
                endRowIndex < i + this.rows[i].table_cells[j].rowspan - 1
              ) {
                endRowIndex = i + this.rows[i].table_cells[j].rowspan - 1;
                dragSelectedCells();
              }
            }

            if (
              startRowIndex <= i &&
              i <= endRowIndex &&
              startColIndex <= this.colIndices[i][j] &&
              this.colIndices[i][j] <= endColIndex
            ) {
              this.currentCells.push({
                rowIndex: i,
                cellIndex: j,
                colIndex: this.colIndices[i][j],
                rowspan: this.rows[i].table_cells[j].rowspan,
                colspan: this.rows[i].table_cells[j].colspan,
              });
            }
          }
        }
      };
      dragSelectedCells();
    },
  },
};
</script>
<style lang="scss">
.price-calendar-page {
  .submit_form {
    padding-bottom: 6rem;
  }
  // type style
  .select-bg-type {
    width: 30px;
    height: 30px;
    margin: 4px;
    margin-right: 1rem;
  }
  .color-selected {
    box-shadow: inset 0 0 5px #636f83;
  }
  .c-success {
    background: #b5e9b1;
    border: 1px solid #008000;
  }
  .c-warning {
    background: #f8e8c6;
    border: 1px solid #f9b115;
  }
  .c-info {
    background: #d9ecfd;
    border: 1px solid #3399ff;
  }
  ::-webkit-scrollbar {
    width: 0px;
  }
  ::-webkit-scrollbar-track {
    display: none;
  }
  .custom-calendar.vc-container {
    --day-border: 1px solid #b8c2cc;
    --day-border-highlight: 1px solid #b8c2cc;
    --day-width: 90px;
    --day-height: 90px;
    --weekday-bg: #f8fafc;
    --weekday-border: 1px solid #eaeaea;
    border-radius: 0;
    width: 100%;
    & .vc-header {
      background-color: #f1f5f8;
      padding: 10px 0;
    }
    & .vc-weeks {
      padding: 0;
    }
    & .vc-weekday {
      background-color: var(--weekday-bg);
      border-bottom: var(--weekday-border);
      border-top: var(--weekday-border);
      padding: 5px 0;
    }
    & .vc-day {
      padding: 0 5px 3px 5px;
      text-align: left;
      height: var(--day-height);
      min-width: var(--day-width);
      background-color: white;
      &.weekday-1,
      &.weekday-7 {
        background-color: #eff8ff;
      }
      &:not(.on-bottom) {
        border-bottom: var(--day-border);
        &.weekday-1 {
          border-bottom: var(--day-border-highlight);
        }
      }
      &:not(.on-right) {
        border-right: var(--day-border);
      }
    }
    & .vc-day-dots {
      margin-bottom: 5px;
    }
    .input-c {
      input {
        // padding: 0;
        height: 1.5rem;
        background: #d2fde5;
        border: 1px solid #70bb92;
      }
    }
  }

  // color picker start
  .color-picker-table {
    td,
    th {
      vertical-align: middle;
    }
  }

  .color-code {
    padding: 10px 0px 0px 10px;
    float: left;
  }

  .vue-swatches {
    float: left;
  }

  .overlay-table {
    .vue-swatches {
      float: none;
    }
  }

  .vue-swatches__check__wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .vue-swatches__check__circle {
    width: 21px;
    height: 21px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.15);
  }

  .vue-swatches__check__path {
    fill: #fff;
  }

  .vue-swatches__swatch {
    position: relative;
    display: inline-block;
    font-size: 0;
    width: 30px !important;
    height: 30px !important;
    border-radius: 5px !important;
    margin: 0 10px 0 0 !important;
    border: 1px solid #cccccc;
  }

  fieldset[disabled] .vue-swatches {
    pointer-events: none;
  }

  .vue-swatches {
    position: relative;
    display: inline-block;
    outline: none;
  }

  .vue-swatches__trigger {
    width: 30px !important;
    height: 30px !important;
    border: 1px solid #cccccc;
  }
  .select-lbl {
    //select batch
    font-size: 0.8em;
    line-height: 1rem;
    display: inline-block;
    margin-left: 4px;
    border-radius: 5px;
    padding: 0 5px;
  }

  .btmn {
    cursor: pointer;
    flex-basis: 100%;
  }
  .flex-container {
    display: flex;
    align-items: stretch;
    .edit-b {
      width: 80px;
    }
  }
}

// -------
.custom-calendar-select {
  .raw-calendar {
    width: 100%;
    tr {
      td {
        font-size: 0.9em;
        height: 80px;
        min-width: 60px;
        padding: 0 5px 3px 5px;
        border: 1px solid #b8c2cc;
        text-align: left;
        vertical-align: top;
        cursor: pointer;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        &.is-active {
          background: #d4e8f5;
        }
      }
    }
  }

  .calendar-top {
    width: 100%;
    border-top: 2px solid #d4d4d4;
    .calendar-week {
      border-left: 1px solid #b8c2cc;
      border-right: 1px solid #b8c2cc;

      th {
        min-width: 60px;
        padding: 0 5px;
        background-color: #f8fafc;
        border-bottom: 1px solid #eaeaea;
        border-top: 1px solid #eaeaea;
        color: #a0aec0;
        text-align: center;
      }
    }
    .month-navi {
      border-left: 1px solid #b8c2cc;
      border-right: 1px solid #b8c2cc;

      th {
        background-color: #f1f5f8;
        padding: 10px 0;
        text-align: center;
        &:nth-child(1) {
          text-align: left;
          font-size: 1.2em;
          padding-left: 1.4em;
        }
        &:nth-child(3) {
          text-align: right;
          padding-right: 1.4em;
          font-size: 1.2em;
        }
      }
    }
  }
}
.date-cell {
  position: relative;
}
</style>